.lds-dual-ring {
  align-self: center;
}

.location,
.price,
.humidity,
.temp,
.time {
  color: var(--terminal-secondary);
}

.powerade-table,
.conditions-table {
  margin-top: 1rem;
  & tr > td {
    border: 1px solid var(--terminal-primary);
    border-collapse: collapse;
    border-radius: 5px;
    padding: 5px;
  }
  width: fit-content;
}

em {
  padding: 1rem 0;
}

button {
  color: white;
  background-color: black;
  border: 1px solid var(--terminal-primary);
  padding: 0.5rem 1rem;
  &:hover {
    background-color: rgba(255, 255, 255, 0.171);
  }
}
