:root {
  --terminal-primary: rgb(23, 199, 23);
  --terminal-secondary: rgb(21, 115, 255);
  --terminal-green: rgb(23, 199, 23);
  --terminal-blue: rgb(21, 115, 255);
  --terminal-pink: rgb(255, 39, 242);
  --terminal-lightblue: rgb(131, 238, 255);
  --terminal-purple: rgb(176, 38, 255);
  --terminal-white: rgb(255, 255, 255);
  --terminal-orange: rgb(255, 115, 34);
  --terminal-yellow: rgb(240, 255, 30);
  --terminal-red: rgb(255, 20, 20);
}

:root .green {
  --terminal-primary: var(--terminal-green);
  --terminal-secondary: var(--terminal-blue);
}

:root .pink {
  --terminal-primary: var(--terminal-pink);
  --terminal-secondary: var(--terminal-blue);
}

:root .lightblue {
  --terminal-primary: var(--terminal-lightblue);
  --terminal-secondary: var(--terminal-blue);
}

:root .orange {
  --terminal-primary: var(--terminal-orange);
  --terminal-secondary: var(--terminal-blue);
}

:root .white {
  --terminal-primary: var(--terminal-white);
  --terminal-secondary: var(--terminal-blue);
}

:root .purple {
  --terminal-primary: var(--terminal-purple);
  --terminal-secondary: var(--terminal-blue);
}

:root .yellow {
  --terminal-primary: var(--terminal-yellow);
  --terminal-secondary: var(--terminal-blue);
}

:root .blue {
  --terminal-primary: var(--terminal-blue);
  --terminal-secondary: var(--terminal-blue);
}

:root .red {
  --terminal-primary: var(--terminal-red);
  --terminal-secondary: var(--terminal-blue);
}

:root .red .invalid {
  color: gray;
}

body {
  background: black;
  margin: 0;
  /* font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
