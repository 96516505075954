.header {
  display: grid;
  grid-template-columns: 10fr 1fr;
  border: 1px solid var(--terminal-primary);
  padding-left: 1em;

  & * {
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
      Bitstream Vera Sans Mono, Courier New;
    font-size: 1.2rem;
  }

  & .console {
    color: white;
    margin-top: 0.6rem;
  }

  & .primary-highlight {
    color: var(--terminal-primary);
  }

  & .secondary-highlight {
    color: var(--terminal-secondary);
  }
}

.links-right,
.link {
  background: none;
  border-left: 1px solid var(--terminal-primary);
  color: white;
  display: grid;
  text-align: center;
  text-decoration: none;
}

.link:hover {
  color: var(--terminal-secondary);
}

.projects-nav {
  border-bottom: 1px solid var(--terminal-primary);
  border-top: 1px solid var(--terminal-primary);
}

.api-nav {
  border-bottom: 1px solid var(--terminal-primary);
}

.command-input {
  background-color: black;
  border: none;
  color: white;
  padding-left: 10px;
  outline: none;
  width: 55%;
  margin-bottom: 5px;

  &.valid {
    color: var(--terminal-primary);
  }

  &.invalid {
    color: red;
  }
}

@media screen and (max-width: 500px) {
  .path {
    display: block;
  }
}
