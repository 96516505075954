.error {
  .content.error {
    color: red;
  }
  div + li.content:before {
    content: none;
  }

  .content.cause {
    color: yellow;
  }

  .content.solution {
    color: var(--terminal-green);
  }
}

.error-list {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.error.card {
  margin: auto;
  margin-top: 2%;
  min-width: fit-content;
  min-height: 40vh;
  & .content {
    display: grid;
  }
}
