.help-modal[open] {
  animation: zoomIn 0.75s;
  background-color: rgba(35, 59, 197, 0.92);
  border: 1px solid white;
  @media screen and (max-width: 500px) {
    .close-modal {
      background: none;
      border: none;
      color: red;
      font-weight: bold;
      outline: none;
    }
  }
}

.help-modal::backdrop {
  background: rgba(148, 239, 253, 0.35);
}

.help-container {
  color: white;
  width: 80vw;
  display: grid;
  grid-template-areas:
    'header'
    'color'
    'colors'
    'page'
    'pages'
    'linkedin'
    'github';

  h1 {
    border: 2px dashed rgba(255, 255, 255, 0.534);
    font-size: 1.3em;
    margin-top: 0;
    padding: 10px;
    text-align: center;
    text-shadow: 2px 2px 4px #2e2d2d;
    text-transform: uppercase;

    & div {
      overflow-wrap: break-word;
      padding: 10px;
    }

    & div:nth-child(1) {
      color: rgb(199, 46, 179);
    }

    & div:nth-child(2) {
      color: rgb(127, 175, 37);
    }

    & .invalid {
      color: red;
    }

    & .valid {
      color: var(--terminal-primary);
    }
  }

  h2 {
    color: var(--terminal-primary);
    color: yellow;
    font-size: 1em;
    margin: 0;
    margin-top: 4px;
    text-shadow: 2px 2px 4px #2e2d2d;

    &.color {
      font-size: 1.3em;
      grid-area: color;

      & div {
        color: gray;
      }
    }

    &.page {
      grid-area: page;
      font-size: 1.2em;

      & div {
        color: gray;
      }
    }

    &.linkedin {
      font-size: 1.2em;
      grid-area: linkedin;
    }

    &.github {
      font-size: 1.2em;
      grid-area: github;
      & div {
        color: gray;
      }
    }
  }

  .colors {
    grid-area: colors;
    list-style-type: none;
    margin: 0;
  }

  .pages {
    grid-area: pages;
    list-style-type: none;
    margin: 0;
  }
}

ul > li {
  text-shadow: 2px 2px 4px #2e2d2d;
}
