.card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2%;
}

.card {
  animation: slideIn 1s;
  border-radius: 5px;
  border: 2px solid var(--terminal-primary);
  margin-bottom: 2%;
  max-height: fit-content;
  min-height: 35vh;
  width: 48%;
}

@media screen and (max-width: 768px) {
  .card,
  .header {
    width: fit-content;
  }
}

.content {
  color: rgb(223, 223, 223);
  font-size: 1.2em;
  margin: 10px;
  padding: 2px 15px;
  text-align: left;
  transition: color linear 0.6s;
}

.home {
  background-color: black;
  background-attachment: fixed;
}

.title {
  border-top: 2px solid var(--terminal-primary);
  border-bottom: 2px solid var(--terminal-primary);
  color: rgb(218, 218, 218);
  font-size: 1.7em;
  font-weight: 600;
  margin-top: 15px;
  transition: color linear 0.6s;
  &:before {
    content: '> ';
    padding-left: 0.5rem;
  }
}
