@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translate(-2.1rem, -2ex);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.App {
  font-family: 'Lucida Console', 'Courier New', monospace;
}

/* Need to figure out how to override this or let the var update the scrollbar color*/
::-webkit-scrollbar-thumb {
  /* background: var(--terminal-primary); */
  background: black;
}

::-webkit-scrollbar-thumb:hover {
  /* background: var(--terminal-primary); */
  background: black;
}

/* Allows for the background to be blurred but not the children */
.App::before {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  filter: blur(5px);
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-top: 25%;
  border-radius: 50%;
  border: 4px solid transparent;
  border-color: var(--terminal-primary) transparent var(--terminal-secondary) transparent;
  animation: lds-dual-ring 1s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
