.ascii-button {
  background-color: transparent;
  color: white;
  cursor: pointer;
  border: 2px solid var(--terminal-primary);
  border-radius: 20px;
  font-size: 1.4rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin: 1rem;
  &:hover {
    code {
      color: var(--terminal-primary);
    }
  }
}

code {
  font-weight: bold;
}

.contact .card {
  margin: auto;
  margin-top: 2%;
  min-width: fit-content;
  min-height: 40vh;
  & .content {
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  .ascii-button {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .ascii-button {
    font-size: 0.55rem;
  }
}
