.takeaway,
.language,
.description {
  color: rgb(223, 223, 223);
  font-size: 1.2em;
  margin: 10px;
  padding: 2px 15px;
  text-align: left;
  transition: color 0.6s linear;
}

@media screen and (max-width: 768px) {
  .project {
    width: 100vw;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul > div {
  font-size: 1.1em;
  text-decoration: underline;
  padding-bottom: 10px;
}

div ~ li {
  padding-left: 20px;
  &:before {
    content: '- ';
  }
}
